import { Children, useRef } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import {
    closeClock,
    closeBlog,
    closePort,
    closeTech,
    closeCalc,
} from '../data/home/homeSlice'

function Window({ children, addClasses, title, URL }) {
    const dispatch = useDispatch()
    let windowRef = useRef(null)

    function closeWindow(e) {
        e.preventDefault()
        let winId = windowRef.current.id
        switch (winId) {
            case 'clock':
                dispatch(closeClock())
                break
            case 'calculator':
                dispatch(closeCalc())
                break
            case 'blog':
                dispatch(closeBlog())
                break
            case 'technologies':
                dispatch(closeTech())
                break
            case 'portfolio':
                dispatch(closePort())
                break
            default:
                break
        }
    }
    return (
        <div
            className={`window draggable ${addClasses}`}
            ref={windowRef}
            id={title.toLowerCase()}
        >
            <div className='cornerWrap'>
                <div className='corner -top-1 -left-1'></div>
                <div className='corner -top-1 -right-1'></div>
                <div className='corner -bottom-1 -left-1'></div>
                <div className='corner -bottom-1 -right-1'></div>
            </div>

            <div className='inner'>
                <div className='bar'>
                    <div
                        className='barButton cursor-pointer'
                        onClick={closeWindow}
                    >
                        <div className='dash'></div>
                    </div>
                    <div className='title'>
                        {URL !== '' ? (
                            <Link to={URL}>{title}</Link>
                        ) : (
                            <p>{title}</p>
                        )}
                    </div>
                    {URL !== '' ? (
                        <Link to={URL} className='arrowBtn block mx-0 cursor-pointer'>
                            <div className='arrow right'></div>
                        </Link>
                    ) : (
                        <div className='arrowBtn'>
                        </div>
                    )}
                </div>
                {Children.map(children, (child) => (
                    <>{child}</>
                ))}
            </div>
        </div>
    )
}

Window.defaultProps = {
    addClasses: '',
    title: 'Title',
    URL: '',
}

Window.propTypes = {
    addClasses: PropTypes.string,
    title: PropTypes.string,
    URL: PropTypes.string,
}

export default Window
