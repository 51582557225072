import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import { setPage, getProject, reset } from '../data/globalSlice'
import { parseData } from '../data/global'
import Window from '../components/Window'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRectangleXmark } from '@fortawesome/free-solid-svg-icons'
import Spinner from '../components/Spinner'

function Project() {
    const { project } = useSelector((state) => state.global)
    let [isLoading, setIsLoading] = useState(true)
    let [imgOpen, setImgOpen] = useState(false)

    let bigImgRef = useRef(null)

    let { slug } = useParams()

    const dispatch = useDispatch()
    let navigate = useNavigate()

    useEffect(() => {
        dispatch(setPage('project'))
        dispatch(getProject(slug)).then((res) => {
            if (res.meta.requestStatus === 'rejected') navigate('/notFound')
        })
        setTimeout(() => {
            setIsLoading(false)
        }, 250)
        return () => {
            dispatch(reset())
        }
    }, [dispatch, navigate, slug])

    function showBigImg(url) {
        bigImgRef.current.src = url
        setTimeout(() => {
            setImgOpen(true)
        }, 100)
    }

    if (Object.keys(project).length === 0 || isLoading)
        return (
            <div className='page'>
                <div className='spinnerWrap'>
                    <Spinner size={'large'} />
                </div>
            </div>
        )

    return (
        <div className='page post project'>
            <h1>{project.title.rendered}</h1>
            <Window title={project.title.rendered} addClasses={'main'}>
                <div className='innerWrap'>
                    {project.featured_media !== 0 && (
                        <div className='featImg'>
                            <img
                                src={
                                    project.featImg.media_details.sizes.large
                                        .source_url
                                }
                                alt=''
                                onClick={() => {
                                    showBigImg(
                                        project.featImg.media_details.sizes.full
                                            .source_url
                                    )
                                }}
                            />
                        </div>
                    )}
                    {project.otherImgs.length !== 0 && (
                        <Window
                            key={'gallery'}
                            addClasses={'gallery'}
                            title={'Images'}
                        >
                            <div className='innerWrap'>
                                {project.otherImgs.map((img) => {
                                    return (
                                        <img
                                            src={
                                                img.media_details.sizes.medium
                                                    .source_url
                                            }
                                            alt={img.alt_text}
                                            onClick={() => {
                                                showBigImg(
                                                    img.media_details.sizes.full
                                                        .source_url
                                                )
                                            }}
                                            key={`img-${img.id}`}
                                        />
                                    )
                                })}
                            </div>
                        </Window>
                    )}
                    {project.metadata._demoLink[0] !== '' && (
                        <div className='demo'>
                            <a href={project.metadata._demoLink[0]}>
                                <h1>Demo</h1>
                            </a>
                        </div>
                    )}

                    <div className='body'>
                        <div className='content'>
                            <h2>Project Summary</h2>
                            {parseData(project.content.rendered)}
                        </div>
                        <Window title='Tech Used' addClasses={'tech'}>
                            <ul>
                                {project.langs.map((lang) => {
                                    return <li key={lang.id}>{lang.name}</li>
                                })}
                            </ul>
                            {project.metadata._gitHubLink[0] !== '' && (
                                <div className='repo'>
                                    <a href={project.metadata._gitHubLink[0]}>
                                        GitHub Repo
                                    </a>
                                </div>
                            )}
                        </Window>
                    </div>
                </div>
            </Window>
            <div
                className={`bigImg ${!imgOpen && 'hidden'}`}
                onClick={() => setImgOpen(false)}
            >
                <img
                    src=''
                    alt=''
                    onClick={() => setImgOpen(false)}
                    ref={bigImgRef}
                />
                <div className='close'>
                    <FontAwesomeIcon icon={faRectangleXmark} size='3x' />
                </div>
            </div>
        </div>
    )
}

export default Project

