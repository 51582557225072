import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useRef } from 'react'
import Switch from './Switch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarsStaggered } from '@fortawesome/free-solid-svg-icons'

function Header() {
    const { currPageType } = useSelector((state) => state.global)
    let navRef = useRef(null)

    function closeMenu() {
        navRef.current.classList.remove('show')
    }
    
    function handleClick() {
        if (navRef.current.classList.contains('show')) {
            closeMenu()
        } else {
            navRef.current.classList.add('show')
        }
    }

    return (
        <header>
            <div className='headerWrap'>
                <div className='mobile'>
                    <div className='handle' onClick={handleClick}>
                        <FontAwesomeIcon icon={faBarsStaggered} size='2x' />
                    </div>
                </div>
                <nav ref={navRef}>
                    <Link to='/' className='siteName'>
                        ColinWeb.Dev{' '}
                    </Link>
                    <Link to='/' onClick={closeMenu}>Home </Link>
                    <Link to='/portfolio' className={currPageType === 'projects' && 'active'} onClick={closeMenu}>Portfolio</Link>
                    <Link to='/blog' className={currPageType === 'blog' && 'active'} onClick={closeMenu}>Blog</Link>
                    <Link to='/about' className={currPageType === 'about' && 'active'} onClick={closeMenu}>About</Link>
                    <Link to='/contact' className={currPageType === 'contact' && 'active'} onClick={closeMenu}>Contact</Link>
                </nav>
                
                <Switch />
            </div>
        </header>
    )
}

export default Header
